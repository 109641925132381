<template>
  <locale-router-link
    :to="`articles/${item.id}`"
    tag="div"
    v-if="item"
    class="interesting__bottom-item"
  >
    <img
      v-lazy="getImage(item)"
      alt="image interesting"
      class="interesting__bottom-img"
    />
    <div class="interesting__title-wrapper">
      <div class="interesting__bottom-title">
        {{ item.attributes.Header }}/
        <span>{{ item.attributes.Type }}</span>
      </div>
    </div>
  </locale-router-link>
</template>

<script>
export default {
  nema: "ArticlesBottomCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.image?.data?.attributes?.url
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
